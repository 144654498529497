body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}
